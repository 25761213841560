
import {Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import {Language, Vocabulary, VocabularyRangeStore} from "@/model/model";
import VocabPanel from "@/components/VocabPanel.vue";
import {VocService} from "@/service/VocService";
import LearningSuccessScreen from "@/components/LearningSuccessScreen.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import VocabEdit from "@/components/VocabEdit.vue";
import ModalScreen from "@/components/ModalScreen.vue";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {InjectReactive} from "vue-property-decorator";
import VocabStats from "@/components/VocabStats.vue"; // @ is an alias to /src

@Options({
  components: {
    VocabStats,
    ModalScreen,
    VocabEdit,
    ProgressBar,
    LearningSuccessScreen,
    VocabPanel
  },
})
export default class LearningView extends Vue {

  private vocs: Vocabulary[] = [];
  private index = 0;
  private submittedIndex = -1;
  private showFinishScreen = false;
  private language: Language = {label: '', uuid: '', short_label_lang1: '', short_label_lang2: '', both_directions: 0};
  private openEdit = false;

  @InjectReactive() debugMode!: boolean;

  get voc1(): string[] {
    if (this.vocs.length < this.index) {
      return [];
    }
    console.log(this.vocs);
    console.log(this.vocs[this.index]);
    return this.vocs[this.index].text_lang1.split(',');
  }

  get voc2(): string[] {
    if (this.vocs.length < this.index) {
      return [];
    }
    return this.vocs[this.index].text_lang2.split(',');
  }

  next(markAsLearned: boolean): void {
    if (markAsLearned && this.submittedIndex < this.index) {
      const currentIndex = this.index;
      VocService.get().increaseLearningCounter(this.vocs[this.index].uuid)
          .then(_ => this.submittedIndex = currentIndex);
    }

    if (this.index < this.vocs.length - 1) {
      this.index++;
    } else {
      this.showFinishScreen = true;
    }
  }

  finish(): void {
    this.$router.push('./')
  }

  goBack() {
    if (this.index > 0) {
      this.index--;
    }
  }

  async mounted() {
    const langUuid = this.$route.params.langUuid as string;
    VocService.get().getLanguage(langUuid).then(lang => this.language = lang || this.language);
    this.vocs = (await VocService.get().getNextLearnings(langUuid, VocabularyRangeStore.load(langUuid)))
        .sort(() => Math.random() - 0.5);
    this.index = 0;
    if (this.vocs.length == 0) {
      AppSnackbarService.get().show("Noch keine Vokabeln vorhanden.", 'info', 2500);
      this.$router.push("./");
    }
  }

  private onVocabSaved(vocab: Vocabulary): void {
    this.vocs[this.index] = vocab;
    this.openEdit = false;
    AppSnackbarService.get().show("Gespeichert", 'success', 1500);
  }
}
