import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/debug.svg'


const _withScopeId = n => (_pushScopeId("data-v-77d95e28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-root" }
const _hoisted_2 = {
  key: 0,
  class: "debug",
  src: _imports_0
}
const _hoisted_3 = {
  key: 1,
  class: "user"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "initials"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_offline_bar = _resolveComponent("offline-bar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.switchDebugMode()))
    }, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("Vokabeln " + _toDisplayString(_ctx.getLanguage()), 1)
        ]),
        _: 1
      }),
      (_ctx.debugMode)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      (this.$store.state.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (this.$store.state.user.picture)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: this.$store.state.user.picture
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            (!this.$store.state.user.picture)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getInitials(this.$store.state.user)), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_offline_bar),
    _createVNode(_component_router_view)
  ]))
}