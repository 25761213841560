import {POSITION, TYPE} from "vue-toastification";
import {ToastInterface} from "vue-toastification/dist/types/ts/interface";
import {ToastOptions} from "vue-toastification/dist/types/types"; // @ is an alias to /src

export class AppSnackbarService {

    private static INSTANCE = new AppSnackbarService();

    private toast?: any; //ToastInterface;

    public static get(): AppSnackbarService {
        return AppSnackbarService.INSTANCE;
    }

    public setToast(toast: ToastInterface) {
        this.toast = toast;
    }

    public show(message: string, type: 'success' | 'error' | 'warning' | 'info', duration: number | undefined = undefined, position: POSITION = POSITION.TOP_RIGHT): void {
        let toastType = TYPE.DEFAULT;
        let toastDurationByType: number | undefined;
        switch (type) {
            case "error":
                toastType = TYPE.ERROR;
                toastDurationByType = 20000;
                break;
            case "success":
                toastType = TYPE.SUCCESS;
                toastDurationByType = 2000;
                break;
            case "warning":
                toastType = TYPE.WARNING;
                toastDurationByType = 5000;
                break;
            case "info":
                toastType = TYPE.INFO;
                toastDurationByType = 5000;
                break;
        }
        this.toast(message, {
            type: toastType,
            timeout: duration || toastDurationByType,
            position: position
        } as ToastOptions)
    }

}