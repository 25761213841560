
import {Options, Vue} from 'vue-class-component';
import {UserService} from "@/service/UserService";

@Options({
  components: {},
})
export default class LoginView extends Vue {

  async callback(response: any) {
    if (response && response.credential) {
      localStorage.setItem("access_token", response.credential);
      const user = await UserService.get().getUser();
      const redirectUrl = sessionStorage.getItem("redirect_url");
      if (redirectUrl && !redirectUrl.includes("/login")) {
        window.location.href = redirectUrl;
      } else {
        this.$router.push("/");
      }
    }
  }

  async mounted() {
    //if already logged in, redirect to home
    if (await UserService.get().getUser()) {
      this.$router.push("/");
    }
  }

}
