
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    showBack: Boolean
  }
})
export default class FlipCard extends Vue {
  showBack!:boolean;
}
