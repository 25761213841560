
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    total: Number,
    current: Number
  }
})
export default class ProgressBar extends Vue {
  total!: number;
  current!: number;
}
