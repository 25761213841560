
import {Options, Vue} from 'vue-class-component';
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {useToast} from "vue-toastification";
import OfflineBar from "@/components/OfflineBar.vue";
import {UserService} from "@/service/UserService";
import {Language, User} from "@/model/model";
import {ProvideReactive} from "vue-property-decorator";
import {VocService} from "@/service/VocService"; // @ is an alias to /src

interface GroupEntry {
  label: string,
  count?: number,
  color?: string,
  predicate: (learned: number, level: number) => boolean
}

@Options({
  components: {OfflineBar},
})
export default class App extends Vue {

  @ProvideReactive() debugMode = false;
  @ProvideReactive() user: User | null = null;
  @ProvideReactive() lang: Language | null = null;

  switchDebugMode() {
    this.debugMode = !this.debugMode;
  }

  async beforeCreate() {
    AppSnackbarService.get().setToast(useToast());

    const user = await UserService.get().getUser();
    if (user) {
      this.$store.commit('userLoggedIn', user);
      this.user = user;
    }
  }

  async mounted() {
    try {
      screen?.orientation?.lock('portrait') ;
    } catch (err) {
      //ignore
    }
  }

  getInitials(user: User) {
    const names = user.name.split(' ');
    if (names.length == 1) {
      return names[0].substring(0, 1);
    } else {
      return names[0].substring(0, 1) + names[names.length - 1].substring(0, 1)
    }
  }

  getLanguage() {
    const langUuid = this.$route.params.langUuid;
    return langUuid || '';
  }
}
