import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1630600f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vocab-stats-container" }
const _hoisted_2 = {
  key: 0,
  class: "stats"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.stats)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, " 🆕 " + _toDisplayString(_ctx.niceDay(_ctx.stats.created * 1000, 'auto')), 1),
          _createElementVNode("span", null, " 📚 " + _toDisplayString(_ctx.stats.learned || 0), 1),
          _createElementVNode("span", null, " 👌🏼 " + _toDisplayString(_ctx.language?.short_label_lang1) + ":" + _toDisplayString(_ctx.stats.succeeded_lang2 || 0) + " | " + _toDisplayString(_ctx.language?.short_label_lang2) + ":" + _toDisplayString(_ctx.stats.succeeded_lang1 || 0), 1),
          _createElementVNode("span", null, " 😑 " + _toDisplayString(_ctx.language?.short_label_lang1) + ":" + _toDisplayString(_ctx.stats.missed_lang2 || 0) + " | " + _toDisplayString(_ctx.language?.short_label_lang2) + ":" + _toDisplayString(_ctx.stats.missed_lang1 || 0), 1),
          _createElementVNode("span", null, " 🪜 " + _toDisplayString(_ctx.language?.short_label_lang1) + ":" + _toDisplayString(_ctx.stats.level_lang2 || 0) + " | " + _toDisplayString(_ctx.language?.short_label_lang2) + ":" + _toDisplayString(_ctx.stats.level_lang1 || 0), 1),
          _createElementVNode("span", null, " ⬆️ " + _toDisplayString(_ctx.language?.short_label_lang1) + ":" + _toDisplayString(_ctx.stats.last_level_lang2 ? _ctx.since((new Date().getTime() / 1000) - _ctx.stats.last_level_lang2) : '-') + " | " + _toDisplayString(_ctx.language?.short_label_lang2) + ":" + _toDisplayString(_ctx.stats.last_level_lang1 ? _ctx.since((new Date().getTime() / 1000) - _ctx.stats.last_level_lang1) : '-'), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}