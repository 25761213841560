
import {Options, Vue} from 'vue-class-component';
import FinishScreen from "@/components/FinishScreen.vue";

@Options({
  components: {FinishScreen},
  props: {
    numberCorrect: Number,
    numberFailed: Number
  }
})
export default class QuizSuccessScreen extends Vue {
  numberCorrect!: number;
  numberFailed!: number;
}
