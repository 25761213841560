import {min} from "rxjs";

export const niceDay = (value: string, includeTime: ('always' | 'auto' | 'no') = 'no'): string => {
    if (!value) {
        return "";
    }

    const todayIsoDate = new Date().toISOString().substring(0, 10);
    const yesterDayIsoDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
        .toISOString().substring(0, 10);
    const date = new Date(value);
    const locale = 'de';

    let timeStyle = date.toLocaleTimeString(locale, { timeStyle: 'short' });
    if (includeTime == 'no') timeStyle = '';
    const time = ' ' + timeStyle;

    const tage = 24 * 60 * 60 * 1000;
    if (value == todayIsoDate) {
        return "Heute" + time
    } else if (value == yesterDayIsoDate) {
        return "Gestern" + time

    } else if (date.getTime() + 7 * tage >= new Date().getTime()) {
        // weniger als 7 Tage her: Nur den ausgeschriebenen Wochentag zeigen
        return new Date(value).toLocaleDateString(locale, { weekday: 'long' }) + time;

    } else if (date.getTime() + 21 * tage >= new Date().getTime()) {
        // weniger als 21 Tage her: Wochentagsabkürzung und ohne Jahr
        return date.toLocaleDateString(locale, { weekday: 'short' })
            + ' ' + date.toLocaleDateString('de').replace('' + date.getFullYear(),'') + time;

    } else if (date.getTime() + 100 * tage >= new Date().getTime()) {
        // weniger als 100 Tage her: Datum ohne Jahr
        return date.toLocaleDateString('de').replace('' + date.getFullYear(),'') + time;
    }
    // lange her
    return new Date(value).toLocaleDateString(locale, { weekday: 'long' }) + time;
}

export const since = (seconds: number): string => {
    if (!seconds || isNaN(seconds)) {
        return '';
    }
    if (seconds < 60*60) {
        return (seconds / 60).toFixed(0) + " min";
    }
    if (seconds < 60*60*24) {
        return (seconds / 60 / 60).toFixed(0) + " h";
    }
    return (seconds / 60 / 60 / 24).toFixed(0) + " d";
}

export const levenshteinDistance = (a: string, b: string): number => {
    // Basisfall: Wenn einer der Strings leer ist, ist die Distanz
    // gleich der Länge des anderen Strings
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    // Initialisierung der Matrix für die Distanzwerte
    const distanceMatrix = [];
    for (let i = 0; i <= b.length; i++) {
        distanceMatrix[i] = [i];
    }
    for (let j = 0; j <= a.length; j++) {
        distanceMatrix[0][j] = j;
    }

    // Berechnung der Distanzwerte
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            const cost = a[j - 1] === b[i - 1] ? 0 : 1;
            distanceMatrix[i][j] = Math.min(
                distanceMatrix[i - 1][j] + 1, // Löschen
                distanceMatrix[i][j - 1] + 1, // Einfügen
                distanceMatrix[i - 1][j - 1] + cost // Ersetzen
            );
        }
    }

    // Rückgabe der Distanz
    return distanceMatrix[b.length][a.length];
}

export const sortArrayByLevenshteinDistance = (arrayToSortAfter: string[], arrayToSort: string[]): string[] => {
    const result = [];
    const arrayToSortCopy = [...arrayToSort];

    for (let i = 0; i < arrayToSortAfter.length; i++) {
        const distances = arrayToSortCopy.map(s => levenshteinDistance(s, arrayToSortAfter[i]));
        const minDistance = Math.min(...distances);
        const minDistanceIndex = distances.indexOf(minDistance);
        result.push(arrayToSortCopy[minDistanceIndex]);
        arrayToSortCopy.splice(minDistanceIndex, 1); // das ausgewählte element rausnehmen
    }
    return result;
}

export const sortArrayByLevenshteinDistance2 = (arrayToSortAfter: string[], arrayToSort: string[]): string[] => {
    arrayToSortAfter = arrayToSortAfter.map(x => !x ? '999999999999999999999999999999999999' : x);
    //arrayToSort = arrayToSort.map(x => !x ? '999999999999999999' : x);

    return arrayToSort.sort((a,b) => {
        // Berechnung der Levenshtein-Distanzen zwischen a und b im Vergleich
        // zu den am nächsten liegenden Strings im zweiten Array
        a = !a ? '999999999999999999999999999999999999' : a;
        b = !b ? '999999999999999999999999999999999999' : b;

        const distancesA = arrayToSortAfter.map(str => levenshteinDistance(a, str));
        const distancesB = arrayToSortAfter.map(str => levenshteinDistance(b, str));
        const minDistanceA = Math.min(...distancesA);
        const minDistanceB = Math.min(...distancesB);

        const posOfMinDistanceA = distancesA.indexOf(minDistanceA);
        const posOfMinDistanceB = distancesB.indexOf(minDistanceB);

        // Rückgabe eines negativen Werts, wenn a vor b kommen sollte,
        // eines positiven Werts, wenn b vor a kommen sollte, und 0, wenn
        // a und b gleich sind
        return posOfMinDistanceA - posOfMinDistanceB;
        //return minDistanceA - minDistanceB;
    });
}