import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66d5d0e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progress",
      style: _normalizeStyle('width: ' + ((_ctx.current/_ctx.total)*100).toFixed(0) +'%')
    }, " ", 4)
  ]))
}