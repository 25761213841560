
import {Options, Vue} from 'vue-class-component';
import {VocService} from "@/service/VocService";
import {Language, Page, uuidv4, Vocabulary} from "@/model/model";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import ModalScreen from "@/components/ModalScreen.vue";
import VocabEdit, {VocabEditSavedEvent} from "@/components/VocabEdit.vue";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {POSITION} from "vue-toastification";

@Options({
  components: {VocabEdit, ModalScreen},
})
export default class VocListView extends Vue {

  private page: Page<Vocabulary> | null = null;
  private language: Language | null = null;

  private pageSize = 35;
  private pageIndex = 0;

  private searchText$ = new Subject<string>();
  private searchText = '';

  private vocToEdit: Vocabulary | null = null;
  private destroy = new Subject<void>();

  async mounted() {
    VocService.get().getLanguage(this.$route.params.langUuid as string)
        .then(lang => this.language = lang!);
    await this.loadVocs();

    this.searchText$.pipe(
        debounceTime(500),
        takeUntil(this.destroy),
    ).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.pageIndex = 0;
      this.loadVocs();
    })
  }

  unmounted() {
    this.destroy.next();
  }

  private handleSearchTextChange(evt: InputEvent) {
    this.searchText$.next((evt.target as any).value);
  }

  private editVoc(voc: Vocabulary) {
    this.vocToEdit = voc;
  }

  private createVoc() {
    if (!this.language) {
      return;
    }

    this.vocToEdit = {
      uuid: uuidv4(),
      languagepair_uuid: this.language!.uuid!,
      text_lang1: '',
      text_lang2: '',
    };
  }

  private onDeleted() {
    this.vocToEdit = null;
    this.loadVocs();
  }

  private async onVocabSaved(voc: VocabEditSavedEvent) {
    this.vocToEdit = null;

    AppSnackbarService.get().show("Vokabel gespeichert", 'success', 1500, voc.createAnother ? POSITION.BOTTOM_RIGHT : POSITION.TOP_RIGHT);
    this.loadVocs();

    if (voc.createAnother) {
      this.$nextTick(() => { //damit die Komponente auch neu initialisiert wird
        this.createVoc();
      })
    }
  }

  private loadVocs() {
    VocService.get().getVocabularyPaged(this.$route.params.langUuid as string,
        { text: this.searchText },
        {limit: this.pageSize, page: this.pageIndex})
        .then(page => {
          this.page = page;
        });
  }

  async navigatePage(direction: number): Promise<void> {
    if (!this.page) return;
    if (direction < 0) {
      if (this.pageIndex > 0) {
        this.pageIndex--;
        await this.loadVocs();
      }
    } else {
      if (this.pageIndex < this.page.totalPages - 1) {
        this.pageIndex++;
        await this.loadVocs();
      }
    }
  }
}
