export interface User {
    uuid: string;
    name: string;
    picture: string;
}

export interface Page<T> {
    content: T[],
    totalPages: number, // total number of pages
    totalElements: number, // total number of items
    page: number, // current page number (0 based)
    size: number, //page size
    numberOfElements: number, // number of items on the current page
}

export interface Activity {
    user_uuid: string;
    languagepair_uuid: string;
    isodate: string;
    learned: number;
    missed?: number;
    succeeded?: number;
}

export interface Vocabulary {
    uuid: string;
    text_lang1: string;
    text_lang2: string;
    example_lang1?: string;
    example_lang2?: string;
    languagepair_uuid: string;
    created?: number;
    rangeindex?: number;
}

export interface VocabularyIndex {
    rangeindex: number;
    label: string;
}

export interface VocabularyRange {
    from: VocabularyIndex,
    to: VocabularyIndex
}

const vocabDefaultRange: VocabularyRange = {
    from: {rangeindex: 0, label: "Anfang"},
    to: {rangeindex: 999999999, label: "Ende"},
}

export class VocabularyRangeStore {

    static load(langUuid: string): VocabularyRange {
        return JSON.parse(localStorage.getItem("vocabRange-" + langUuid) || JSON.stringify(vocabDefaultRange));
    }

    static defaults(): VocabularyRange {
        return { ...vocabDefaultRange };
    }

    static store(langUuid: string, range: VocabularyRange) {
        if (range) {
            localStorage.setItem("vocabRange-" + langUuid, JSON.stringify(range));
        } else {
            localStorage.removeItem("vocabRange-" + langUuid);
        }
    }
}

export interface VocabularyQuizInfo {
    lastTimeAskedForLang1?: boolean
}

export interface VocabularyStats {
    uuid: string;

    // Die Zähler für Sprache bedeuten, dass dem Anwender
    // die Sprache 1 gezeigt wurde und er Sprache 2 wissen musste.
    level_lang1: number;
    level_lang2: number;
    last_level_lang1: number; // letzte Levelerhöhung
    last_level_lang2: number; // letzte Levelerhöhung
    missed_lang1: number;
    missed_lang2: number;
    succeeded_lang1: number;
    succeeded_lang2: number;

    learned: number;
    last: number; // letztes lernen/abfragen
}

export interface VocabularyWithStats extends Vocabulary, VocabularyStats {
}

export enum StatsField {
    learned= "learned",
    missed_lang1 = "missed_lang1",
    missed_lang2 = "missed_lang2",
    succeeded_lang1 = "succeeded_lang1",
    succeeded_lang2 = "succeeded_lang2"
}

export interface VocabularyLearningUpdate {
    vocabulary_uuid: string;
}

export interface VocabularyLevelUpdate {
    vocabulary_uuid: string;
    level_direction: -1 | 0 | 1;
    language_number: 1 | 2;
    user_answer?: string;
}

export interface LevelStatsEntry {
    count: number,
    learned: number,
    level: number
}

export interface Language {
    uuid: string,
    label: string,
    both_directions: number,
    short_label_lang1?: string,
    short_label_lang2?: string
}

export function uuidv4() {
    // eslint-disable-next-line
    // @ts-ignore
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
