import {
    Language,
    LevelStatsEntry, Page,
    StatsField, User,
    Vocabulary,
    VocabularyLearningUpdate,
    VocabularyLevelUpdate,
} from "@/model/model";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {AbstractService} from "@/service/AbstractService";

export class UserService extends AbstractService {

    private static INSTANCE = new UserService();

    private user: User | null = null;

    public static get(): UserService {
        return UserService.INSTANCE;
    }

    public async getUser(): Promise<User | null> {
        if (this.user) {
            return this.user;
        }

        try {
            const response = await this.fetchAuthorized(`/users/me`);
            if (response.status == 401) {
                return null;
            }
            this.user = await response.json();
            return this.user;
        } catch (err) {
            AppSnackbarService.get().show("Fehler beim Laden des Users: " + (err as any).message, 'error');
            throw err;
        }
    }

    public logout(): void {
        this.user = null;
        localStorage.removeItem("access_token");
    }

}