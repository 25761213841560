
import {Options, Vue} from 'vue-class-component';
import {Language, Vocabulary} from "@/model/model";
import {VocService} from "@/service/VocService";
import VocabStats from "@/components/VocabStats.vue";
import {AppSnackbarService} from "@/service/AppSnackbarService";

export interface VocabEditSavedEvent extends Vocabulary {
  createAnother: boolean
}

@Options({
  components: {VocabStats},
  props: {
    vocabulary: Object,
    language: Object
  }, emits: ['saved', 'close', 'deleted' ]
})
export default class VocabEdit extends Vue {
  vocabulary!: Vocabulary;
  language!: Language;
  viewHeight = window.visualViewport.height;
  private intervalHandle!: number;

  private deleteConfirm = false;

  onResize = (evt: any) => {
    //
  }

  private async save(andNew: boolean): Promise<void> {
    const saved = await VocService.get().saveVocabulary(this.language.uuid, this.vocabulary);
    const createAnother = andNew && !(this.vocabulary?.created || 0 > 0)
    this.$emit('saved', { ...saved, createAnother: createAnother } as VocabEditSavedEvent);
  }

  private async deleteVocab(force: boolean): Promise<void> {
    if (force) {
      await VocService.get().deleteVocabulary(this.language.uuid, this.vocabulary.uuid);
      AppSnackbarService.get().show("Gelöscht", 'success', 1500);
      this.$emit('deleted');
    } else {
      this.deleteConfirm = true;
      setTimeout(() => this.deleteConfirm = false, 5000);
    }
  }

  mounted() {
    /**
     this.intervalHandle = setInterval(() => {
      this.viewHeight = window.visualViewport.height
    }, 1000); **/
    this.$nextTick(() => (this.$refs.firstEdit as any).focus());
  }

  created() {
    window.addEventListener('resize', this.onResize);
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    // clearInterval(this.intervalHandle);
  }

}
