
import { Options, Vue } from 'vue-class-component';
import {Watch} from "vue-property-decorator";
import {VocService} from "@/service/VocService";
import {VocabularyStats, Language } from "@/model/model";
import {niceDay, since} from "@/service/utils";

@Options({
  props: {
    uuid: String,
  }
})
export default class VocabStats extends Vue {

  private stats: VocabularyStats | null = null;
  private niceDay = niceDay;
  private since = since;
  private language: Language | undefined;

  @Watch('uuid', { immediate: true }) async onUuidChanged(uuid: string): Promise<void> {
    this.stats = await VocService.get().getVocabularyStats(this.$route.params.langUuid as string, uuid);
  }

  async created() {
    this.language = await VocService.get().getLanguage(this.$route.params.langUuid as string);
  }
}
