// Create a new store instance.
import {createStore} from "vuex";
import {User} from "@/model/model";

export const store = createStore({
    state() {
        return {
            user: null
        }
    },
    mutations: {
        userLoggedIn(state: any, newUser: User) {
            state.user = newUser;
        },
        userLoggedOut(state: any) {
            state.user = null;
        }
    },
})
