import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48b3af84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flip-card-front" }
const _hoisted_2 = { class: "flip-card-back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flip-card", { 'show-back' : _ctx.showBack }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flip-card-inner", { 'flip-slow' : _ctx.showBack, 'flip-fast' : !_ctx.showBack }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "front", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "back", {}, undefined, true)
      ])
    ], 2)
  ], 2))
}