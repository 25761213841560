
import {Options, Vue} from 'vue-class-component';
import {VocService} from "@/service/VocService";
import {Language, Page, uuidv4, Vocabulary} from "@/model/model";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import ModalScreen from "@/components/ModalScreen.vue";
import VocabEdit from "@/components/VocabEdit.vue";
import {debounceTime, Subject, takeUntil} from "rxjs";

@Options({
  components: {},
})
export default class VocImportView extends Vue {

  private previewVocs: Array<Vocabulary> | null = [];
  private language: Language | null = null;

  private importText$ = new Subject<string>();
  private importText = '';

  private vocToEdit: Vocabulary | null = null;
  private importRunning = false;
  private destroy = new Subject<void>();

  async mounted() {
    VocService.get().getLanguage(this.$route.params.langUuid as string)
        .then(lang => this.language = lang!);

    this.importText$.pipe(
        debounceTime(500),
        takeUntil(this.destroy),
    ).subscribe((importText: string) => {
      this.importText = importText;
      this.previewVocs = this.loadPreview(importText);
    })
  }

  unmounted() {
    this.destroy.next();
  }

  private handleInputChanged(evt: InputEvent) {
    this.importText$.next((evt.target as any).value);
  }

  private loadPreview(text: string): Array<Vocabulary> {
    const lines = text.split("\n").filter(line => line.trim().length > 0);
    const vocs = lines.map(line => {
      const langs = line.split(/\t|;/).filter(part => part.trim());
      const voc = {
        uuid: uuidv4(),
        text_lang1: langs[0],
        text_lang2: langs[1],
        languagepair_uuid: this.language?.uuid
      } as Vocabulary
      return voc;
    })
    return vocs;
  }

  private async importVocs() {
    if (this.importRunning) {
      return;
    }
    this.importRunning = true;
    let saved = 0;
    try {
      const vocs: Array<Vocabulary> = this.loadPreview(this.importText);
      if (vocs.length == 0) {
        return;
      }

      saved = 0;
      for (const voc of vocs) {
        await VocService.get().saveVocabulary(this.language?.uuid as string, voc);
        saved++;
      }
    } finally {
      this.importRunning = false;
    }

    AppSnackbarService.get().show(`${saved} Vokabeln gespeichert.`, 'success', 2000);
    this.$router.push('./');
  }
}
