import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc0010e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "voc-entries" }
const _hoisted_2 = { class: "language-icon" }
const _hoisted_3 = { class: "speak-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["voc-container", { 'locked' : _ctx.locked }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.langCode), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vocs, (voc) => {
        return (_openBlock(), _createElementBlock("div", {
          key: voc,
          class: "voc-entry"
        }, _toDisplayString(voc), 1))
      }), 128)),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ], 2))
}