
import {Options, Vue} from 'vue-class-component';
import {Vocabulary} from "@/model/model";
import {Emit} from "vue-property-decorator";
import {debounceTime, Subject, takeUntil} from "rxjs";
import {VocService} from "@/service/VocService";

@Options({
  props: {
    label: String,
    searchTextPrefill: String,
  }
})
export default class VocabSelection extends Vue {
  private label!: string
  private searchTextPrefill!: string;

  private searchText$ = new Subject<string>();
  private searchText = '';
  private destroy = new Subject<void>();

  private vocs: Vocabulary[] = [];

  mounted() {
    this.searchText$.pipe(
        debounceTime(500),
        takeUntil(this.destroy),
    ).subscribe((searchText: string) => {
      this.searchText = searchText;
      this.loadVocs();
    })

    if (this.searchTextPrefill) {
      this.searchText$.next(this.searchTextPrefill);
      this.searchText = this.searchTextPrefill;
    }

    this.$nextTick(() => {
      const input = this.$refs.searchInput as HTMLInputElement;
      input.select();
      input.focus();
    })
  }

  unmounted() {
    this.destroy.next();
  }

  private handleSearchTextChange(evt: InputEvent) {
    this.searchText$.next((evt.target as any).value);
  }

  @Emit('close')
  emitCloseEvent(vocab: Vocabulary | null | undefined) {
    //
  }

  private loadVocs() {
    VocService.get().getVocabularyPaged(this.$route.params.langUuid as string,
        { text: this.searchText },
        {limit: 10, page: 0 })
        .then(page => {
          this.vocs = page.content;
        });
  }

}
