
import {Options, Vue} from 'vue-class-component';
import FinishScreen from "@/components/FinishScreen.vue";

@Options({
  components: {FinishScreen},
  props: {
    numberLearned: Number
  }
})
export default class LearningSuccessScreen extends Vue {
  numberLearned!: number;
}
