
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    vocs: Array,
    langCode: String,
    locked: Boolean,
  }
})
export default class VocabPanel extends Vue {
  vocs!: string[];
  langCode!: string

}
