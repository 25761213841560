import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14cbcfc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#app" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "modal-inner",
        style: _normalizeStyle('top: ' + _ctx.top + '; left: ' + _ctx.left + '; bottom: ' + _ctx.bottom + '; right: ' + _ctx.right + ';')
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ])
  ]))
}