import {sortArrayByLevenshteinDistance} from "@/service/utils";

export class CheckResult {

    public answerResults: CheckResultAnswer[] = [];

    public cleanedAnswers: string[] = [];
    public cleanedTranslations: string[] = [];

    public get allAnswersAreValid(): boolean {
        return this.answerResults.filter(a => !a.valid).length == 0;
    }

}

export interface CheckResultAnswer {
    answer: string;
    translation: string;
    valid: boolean;
}

export class InputChecker {

    checkInput(answers: string[], translations: string[]): CheckResult {
        const cleanedAnswers = this.cleanAnswers(answers);
        const cleanedTranslations = this.cleanTranslations(translations);

        // Die Array-Größen erstmal anpassen
        while (cleanedTranslations.length != cleanedAnswers.length) {
            if (cleanedTranslations.length < cleanedAnswers.length) {
                cleanedTranslations.push('');
            } else {
                cleanedAnswers.push('');
            }
        }

        const translationsSortedByAnswers = sortArrayByLevenshteinDistance(cleanedAnswers, cleanedTranslations);

        const result = new CheckResult();
        result.cleanedAnswers = cleanedAnswers;
        result.cleanedTranslations = cleanedTranslations;

        for (let i = 0; i < cleanedAnswers.length; i++) {
            const matches = this.answerMatchesTranslation(cleanedAnswers[i], translationsSortedByAnswers[i]);
            result.answerResults.push({
                valid: matches,
                translation: translationsSortedByAnswers[i],
                answer: cleanedAnswers[i]
            });
        }

        return result;
    }

    private answerMatchesTranslation(answer: string, translation: string) {
        return answer === translation;
    }

    private cleanAnswers(texts: string[]): string[] {
        return texts.map(t => this.cleanAnswer(t)).filter(t => t.length > 0);
    }

    private cleanAnswer(text: string): string {
        return text
            .trim();
    }

    private cleanTranslations(texts: string[]): string[] {
        return texts.map(t => this.cleanTranslation(t)).filter(t => t.length > 0);
    }

    private cleanTranslation(text: string): string {
        return text.trim() // vorher trimmen, damit der "Textanfang" korrekt erkannt wird
            .replace(/\([^)]*\)/g, "")
            .replace(/^der /, "")
            .replace(/^die /, "")
            .replace(/^das /, "")
            .replace(/^the /, "")
            .replace(/\s+/g, ' ') // mehrfache Leerzeichen entfernen
            .trim(); // ja, nochmal trimmen nach der Ersetzung
    }

}