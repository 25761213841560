export abstract class AbstractService {

    async fetchAuthorized(input: RequestInfo, init?: RequestInit): Promise<Response> {
        const response = await fetch(input, {
            ...(init || {}),
            headers: {
                ...(init?.headers || {}),
                'Authorization' : 'Bearer ' + localStorage.getItem("access_token")
            }
        });
        if (response.status == 401) {
            localStorage.removeItem("access_token");
        }
        return response;
    }
}