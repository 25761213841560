
import {Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import FlipCard from "@/components/FlipCard.vue";
import VocabPanel from "@/components/VocabPanel.vue";
import {VocService} from "@/service/VocService";
import {Language} from "@/model/model"; // @ is an alias to /src

@Options({
  components: {},
})
export default class LanguageSelectView extends Vue {
  languages: Language[] = [];

  mounted() {
    VocService.get().getLanguages()
        .then(langs => this.languages = langs);
  }
}
