
import {Options, Vue} from 'vue-class-component';
import {Prop, Ref} from "vue-property-decorator";

@Options({
  props: {}
})
export default class SpeakButton extends Vue {
  @Ref('player') private player!: HTMLAudioElement;
  @Prop('speechUrl') private speechUrl!: string;
  private audioLoaded = true;

  mounted() {
    this.$nextTick(() => {
      this.player.load();
    })
  }

  private speak() {
    console.log("playing")
    this.player.play();
  }

  private onAudioLoaded() {
    this.audioLoaded = true;
    setTimeout(() => this.speak(), 100);
  }

}
