
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {
    top: {
      type: String,
      default: '90px',
      required: false,
    },
    left: {
      type: String,
      default: '40px',
      required: false,
    },
    bottom: {
      type: String,
      default: '40px',
      required: false,
    },
    right: {
      type: String,
      default: '40px',
      required: false,
    }
  }
})
export default class ModalScreen extends Vue {
  private top!: string;
  private left!: string;
  private right!: string;
  private bottom!: string;
}
