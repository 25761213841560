import {createApp} from 'vue'
import App from './App.vue'
import router from './router'

import Toast, {PluginOptions} from "vue-toastification";
import "vue-toastification/dist/index.css";
import vue3GoogleLogin, {GoogleLogin} from 'vue3-google-login'
import {store} from "@/store";
import {VocService} from "@/service/VocService";

const toastOptions: PluginOptions = {
    // You can set your default options here
};

createApp(App)
    .use(router)
    .use(Toast, toastOptions)
    .use(vue3GoogleLogin, {
        clientId: '811857705825-ni8d86ilas6k0efv49630t770fppr26u.apps.googleusercontent.com'
    })
    .use(store)
    .component("google-login", GoogleLogin)
    .mount('#app')
