
import {Options, Vue} from 'vue-class-component';

@Options({
  props: {}
})
export default class OfflineBar extends Vue {
  offline = false;

  mounted() {
    this.offline = !window.navigator.onLine;
    window.addEventListener('online', () => this.offline = false);
    window.addEventListener('offline', () => this.offline = true);
  }

}
